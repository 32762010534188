import { z } from 'zod';

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useAuthStore();

  if (store.isAuthenticated) {
    return navigateTo({ name: 'index' });
  }

  if (!from.name || !['login', 'register'].includes(from.name as string)) {
    try {
      z.string().email().parse(to.query.email);
    } catch {
      return navigateTo({ name: 'index' });
    }
  }
});
